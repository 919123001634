import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO/>
    <h1>404: Not Found</h1>
  </Layout>
)

export default NotFoundPage
